<template>
  <base-view title="Departamentos" icon="columns">
    <div>
      <check-authorization :requiresAuthorizations="['indice departamentos']">
        <div class="card card-header-actions mb-4">
          <b-card-header>
            Departamentos
            <div class="w-120 ml-auto d-flex justify-content-end align-items-center">
              <check-authorization
                :requiresAuthorizations="['importar departamentos']"
                :overrideIf="$store.getters['departamentoModule/isEditingResource']"
              >
                <departamento-import
                  :value="showImportForm || isEditingResource"
                  @input="showImportForm = $event"
                />
              </check-authorization>
              <b-button
                class="btn-sm btn-primary mr-3 mb-2 mb-sm-0 ml-3"
                size="sm"
                @click="onExport">
                Exportar
              </b-button>
              <!-- Form -->
              <check-authorization
                :requiresAuthorizations="['crear departamentos']"
                :overrideIf="$store.getters['departamentoModule/isEditingResource']"
              >
                <departamento-form
                  :value="showEditForm || isEditingResource"
                  @input="showEditForm = $event"
                />
              </check-authorization>
              <!-- Filter -->
              <b-form-select v-model="empresa_id" :options="options" size="sm" class="w-100 mx-2" style="max-width: 400px"
                             :disabled="loading_options"
              ></b-form-select>
              <!-- Search -->
              <departamento-search
                :disabled="!$store.getters['departamentoModule/getAvailableList']"
                placeholder="Buscar por número catastral, cuenta predial o inversionista"
                @filter="(filter) => this.filter = filter"></departamento-search>
            </div>
          </b-card-header>

          <b-card-body class="p-2">
            <departamento-list @on-got-item="showEditForm = true" :search="empresa_id" :filter="filter"></departamento-list>
          </b-card-body>
        </div>
      </check-authorization>
    </div>
  </base-view>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import DepartamentoForm from '@/components/Catalogos/Departamentos/DepartamentoForm'
import DepartamentoList from '@/components/Catalogos/Departamentos/DepartamentoList'
import DepartamentoSearch from '@/components/Catalogos/Departamentos/DepartamentoSearch.vue'
import DepartamentoImport from '@/components/Catalogos/Departamentos/DepartamentoImport'

const STORE_MODULE = 'departamentoModule'

export default {
  name: 'DepartamentosView',

  components: {
    DepartamentoForm,
    DepartamentoList,
    DepartamentoSearch,
    DepartamentoImport
  },

  data () {
    return {
      hideButton: false,
      showEditForm: false,
      showImportForm: false,
      filter: null,
      empresa_id: null,
      options: [],
      loading_options: true
    }
  },

  mounted () {
    this.init()
  },

  computed: {
    ...mapGetters(STORE_MODULE, ['isEditingResource', 'getParams'])
  },

  methods: {
    ...mapActions(STORE_MODULE, ['getResources']),
    ...mapMutations(STORE_MODULE, ['setEmpresaId', 'setCondominioId']),

    async init () {
      this.loading_options = true
      this.load_options()
      this.loading_options = false
    },

    async load_options () {
      // Load options empresa
      const { data } = await this.$store.dispatch('empresaModule/getSelectTree')
      const options = [{
        value: null,
        text: '-- Seleccione una empresa o desarrollo -- '
      }]
      for (var i = 0; i < data.length; i++) {
        options.push({
          value: {
            empresa_id: data[i].id,
            condominio_id: null,
            subcondominio_id: null
          },
          text: `Empresa: ${data[i].razon_social}`
        })
        for (var j = 0; j < data[i].condominios.length; j++) {
          options.push({
            value: {
              empresa_id: data[i].id,
              condominio_id: data[i].condominios[j].id,
              subcondominio_id: null
            },
            text: `-- Desarrollo: ${data[i].condominios[j].nombre}`
          })
          for (var k = 0; k < data[i].condominios[j].subcondominios.length; k++) {
            options.push({
              value: {
                empresa_id: data[i].id,
                condominio_id: data[i].condominios[j].id,
                subcondominio_id: data[i].condominios[j].subcondominios[k].id
              },
              text: `---- Etapa: ${data[i].condominios[j].subcondominios[k].nombre}`
            })
          }
        }
      }
      this.options = options
    },

    async onExport () {
      this.loading = true
      const getval = await this.$store.dispatch(`${STORE_MODULE}/export`)
      this.loading = false

      // Obtenemos periodos
      await this.$onDownload(getval, 'departamentos.xlsx')

      this.$notify({ message: 'Solicitud de exportación de departamentos' })
    }
  }
}
</script>

<style scoped>

</style>
