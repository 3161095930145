<template>
  <div>
    <b-button v-b-modal="'modalImportForm'" variant="warning" size="sm">Importar</b-button>

    <b-modal
      id="modalImportForm"
      v-model="modalImportForm"
      centered
      scrollable
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
      dialog-class="x-modal-md"
    >
      <template #modal-title>
        <span class="text-danger">Importar formato de departamentos</span>
      </template>

      <x-alert-with-errors
        :error="response.error"
        :title="response.message"
        :errors="response.errors"
      />

      <b-overlay :show="isLoading">
        <template #overlay>
          <div class="text-center">
            <loading message="Procesando información, por favor espere..." />
          </div>
        </template>

        <b-card :class="{ isLoading }">
          <b-form v-if="!(response.data)" @submit.prevent="submit">
            <!-- Archivo layout -->
            <b-form-group>
              <template #label>
                <label class="small font-weight-bolder">
                  Archivo layout
                  <span class="text-danger">*</span>
                </label>
              </template>

              <b-form-file
                size="sm"
                v-model="payload.file"
                :state="Boolean(payload.file)"
                placeholder="Selecciona el archivo layout excel...">
                <template slot="file-name" slot-scope="{ names }">
                  <b-badge variant="dark">{{ names[0] }}</b-badge>
                </template>
              </b-form-file>
            </b-form-group>
          </b-form>
          <div v-else>
            <b-table
              id="responseTable"
              :fields="fields"
              :items="response.data"
              striped
              bordered
              responsive
              hover
              stacked="lg"
              show-empty
              small
            >
              <template #cell(numero_catastral)="row">
                <div class="text-danger" v-b-tooltip.hover :title="row.value.error" v-if="row.value.error!==''">{{ row.value.data }}</div>
                <div class="text-primary" v-else>{{ row.value.data }}</div>
              </template>
              <template #cell(cuenta_predial)="row">
                <div class="text-danger" v-b-tooltip.hover :title="row.value.error" v-if="row.value.error!==''">{{ row.value.data }}</div>
                <div class="text-primary" v-else>{{ row.value.data }}</div>
              </template>
              <template #cell(empresa)="row">
                <div class="text-danger" v-b-tooltip.hover :title="row.value.error" v-if="row.value.error!==''">{{ row.value.data }}</div>
                <div class="text-primary" v-b-tooltip.hover :title="row.value.description" v-else>{{ row.value.data }}</div>
              </template>
              <template #cell(desarrollo)="row">
                <div class="text-danger" v-b-tooltip.hover :title="row.value.error" v-if="row.value.error!==''">{{ row.value.data }}</div>
                <div class="text-primary" v-else>{{ row.value.data }}</div>
              </template>
              <template #cell(forma_pago)="row">
                <div class="text-danger" v-b-tooltip.hover :title="row.value.error" v-if="row.value.error!==''">{{ row.value.data }}</div>
                <div class="text-primary" v-else>{{ row.value.data }}</div>
              </template>
              <template #cell(tipo_departamento)="row">
                <div class="text-danger" v-b-tooltip.hover :title="row.value.error" v-if="row.value.error!==''">{{ row.value.data }}</div>
                <div class="text-primary" v-else>{{ row.value.data }}</div>
              </template>
              <template #cell(inversionista)="row">
                <div class="text-danger" v-b-tooltip.hover :title="row.value.error" v-if="row.value.error!==''">{{ row.value.data }}</div>
                <div class="text-primary" v-b-tooltip.hover :title="row.value.description" v-else>{{ row.value.data }}</div>
              </template>
              <template #cell(inicio_contrato)="row">
                <div class="text-danger" v-b-tooltip.hover :title="row.value.error" v-if="row.value.error!==''">{{ row.value.data }}</div>
                <div class="text-primary" v-b-tooltip.hover :title="row.value.description" v-else>{{ row.value.data }}</div>
              </template>
              <template #cell(termino_contrato)="row">
                <div class="text-danger" v-b-tooltip.hover :title="row.value.error" v-if="row.value.error!==''">{{ row.value.data }}</div>
                <div class="text-primary" v-b-tooltip.hover :title="row.value.description" v-else>{{ row.value.data }}</div>
              </template>
              <template #cell(renta_inicial)="row">
                <div class="text-danger" v-b-tooltip.hover :title="row.value.error" v-if="row.value.error!==''">{{ row.value.data }}</div>
                <div class="text-primary" v-else>{{ row.value.data }}</div>
              </template>
              <template #cell(renta_final)="row">
                <div class="text-danger" v-b-tooltip.hover :title="row.value.error" v-if="row.value.error!==''">{{ row.value.data }}</div>
                <div class="text-primary" v-else>{{ row.value.data }}</div>
              </template>
              <template #cell(descuento)="row">
                <div class="text-danger" v-b-tooltip.hover :title="row.value.error" v-if="row.value.error!==''">{{ row.value.data }}</div>
                <div class="text-primary" v-else>{{ row.value.data }}</div>
              </template>
              <template #cell(amueblado)="row">
                <div class="text-danger" v-b-tooltip.hover :title="row.value.error" v-if="row.value.error!==''">{{ row.value.data }}</div>
                <div class="text-primary" v-else>{{ row.value.data }}</div>
              </template>
              <template #cell(valido)="row">
                <div class="text-danger" v-b-tooltip.hover title="Este registro contiene errores" v-if="row.value==='No'">{{ row.value }}</div>
                <div class="text-primary" v-else>{{ row.value }}</div>
              </template>

              <template #cell(show_details)="row">
                <b-button
                  @click="row.toggleDetails"
                  variant="light"
                  class="btn-sm mb-2 btn-block btn-outline-black"
                >
                  Ver errores
                </b-button>
              </template>
              <template #row-details="row">
                <li v-for="error in row.item.errores" v-bind:key="error">
                  {{ error }}
                </li>
              </template>
            </b-table>
          </div>
        </b-card>
      </b-overlay>

      <template #modal-footer>
        <!-- Enviar para crear o actualizar -->
        <div class="w-100">
          <x-form-footer-buttons
            :disabled="Boolean(response.data) && response.data.some((row) => row.valido === 'No')"
            :loading="isLoading"
            :isEditing="false"
            :isCreating="true"
            :btnCreateTitle="response.data ? 'Aplicar cambios' : 'Subir formato'"
            @on-cancel="resetForm"
            @on-create="submit"
            @on-update="submit"
          ></x-form-footer-buttons>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>

const payload = () => ({
  file: null,
  save_file: 0
})

const response = () => ({
  error: false,
  message: '',
  errors: {},
  importacion: null,
  data: null
})

const STORE_MODULE = 'departamentoModule'

export default {
  name: 'DepartamentoImport',

  data () {
    return {
      modalImportForm: false,

      isLoading: false,

      payload: payload(),

      response: response(),

      fields: [
        { key: 'numero_catastral', label: 'Número catastral' },
        { key: 'cuenta_predial', label: 'Cuenta Predial' },
        { key: 'empresa', label: 'Empresa' },
        { key: 'desarrollo', label: 'Desarrollo' },
        { key: 'forma_pago', label: 'Forma de Pago' },
        { key: 'tipo_departamento', label: 'Tipo' },
        { key: 'inversionista', label: 'Inversionista' },
        { key: 'inicio_contrato', label: 'Fecha inicio' },
        { key: 'termino_contrato', label: 'Fecha término' },
        { key: 'renta_inicial', label: 'Renta inicial' },
        { key: 'renta_final', label: 'Renta actual' },
        { key: 'descuento', label: 'Descuento' },
        { key: 'amueblado', label: 'Amueblado' },
        { key: 'valido', label: 'Válido' },
        { key: 'show_details', label: 'Errores' }
      ]
    }
  },

  computed: {
    valido () {
      return Boolean(response.data) && response.data.every((row) => row.valido)
    }
  },

  methods: {
    /**
     * Envía el formulario.
     *
     */
    async submit () {
      this.isLoading = true
      const { error, message, data } = Boolean(this.response.data) && this.response.data.every((row) => row.valido === 'Si')
        ? await this.$store.dispatch(`${STORE_MODULE}/save_import`, { data: this.response.importacion })
        : await this.$store.dispatch(`${STORE_MODULE}/parse_import`, {
          save_file: this.payload.save_file,
          file: this.payload.file
        })
      this.isLoading = false

      this.$notify({ error, message }, 'Envío de formulario')

      this.response.error = error
      this.response.message = message
      this.response.errors = error ? data.errors : {}
      this.response.importacion = error ? null : data.importacion
      this.response.data = error ? null : data.detalle

      if (!error) this.resetForm()
    },

    resetForm () {
      document.activeElement.blur()
      this.payload = payload()
      this.response = response()

      this.modalImportForm = false
    }
  }
}
</script>

<style>
.required_form {
  color: red;
}
.isLoading {
  max-height: 400px !important;
  overflow: hidden;
}
</style>
