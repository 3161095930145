<template>
  <div>
    <slot name="activator" v-bind:onShowModal="onShowModal">
      <b-button @click="onShowModal" variant="success" size="sm">Crear</b-button>
    </slot>

    <b-modal
      v-model="modalEditForm"
      centered
      size="sm"
      scrollable
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
      dialog-class="x-modal-md"
    >
      <template #modal-title>
        <span :class="isEditingResource ? 'text-danger' : ''">{{ showFormTitle }}</span>
      </template>

      <x-alert-with-errors
        :error="response.error"
        :title="response.message"
        :errors="response.errors"
      />

      <b-overlay :show="isLoading">
        <template #overlay>
          <div class="text-center">
            <loading message="Procesando información, por favor espere..." />
          </div>
        </template>

        <b-card :class="{ isLoading }">
          <FormulateForm
            ref="departamentoForm"
            name="departamentoForm"
            v-model="formValues"
            :errors="response.errors"
            @submit="submit"
          >
            <b-row>
              <!-- # puerta -->
              <b-col cols="12" md="4">
                <FormulateInput
                  name="num_puerta"
                  label="Número de puerta"
                  required
                  type="text"
                  validation="required"
                  validation-name="Número de puerta"
                />
              </b-col>

              <b-col cols="12" md="4">
                <FormulateInput
                  name="num_catastral"
                  label="Número catastral"
                  type="text"
                />
              </b-col>

              <b-col cols="12" md="4">
                <FormulateInput
                  name="cuenta_predial"
                  label="Cuenta predial"
                  type="text"
                />
              </b-col>
            </b-row>

            <b-row>
              <!-- Inversionista -->
              <b-col cols="12">
                <strong class="font-weight-bolder small text-sm text-black">Inversionista <span class="text-danger">*</span></strong>
                <x-select
                  class="mt-2"
                  v-model="formValues.inversionista_id"
                  dispatchPath="inversionistaModule/getResource"
                  getterListPath="inversionistaModule/getList"
                  :formatListWith="['id', 'identificacion']"
                  no-validate
                  default-text="Seleccionar inversionista"
                />
              </b-col>
            </b-row>

            <b-row class="mt-3">
              <b-col cols="12" md="6">
                <strong class="font-weight-bolder small text-sm text-black">Empresa <span class="text-danger">*</span></strong>
                <x-select
                  class="mt-2"
                  v-model="formValues.empresa_id"
                  dispatchPath="empresaModule/getResource"
                  getterListPath="empresaModule/getList"
                  :formatListWith="['id', 'razon_social']"
                  no-validate
                  default-text="Seleccionar empresa"
                  required
                />
              </b-col>
              <b-col cols="12" md="6">
                <strong class="font-weight-bolder small text-sm text-black">Edificio <span class="text-danger">*</span></strong>
                <x-select
                  class="mt-2"
                  v-model="formValues.edificio_id"
                  dispatchPath="edificioModule/getResource"
                  getterListPath="edificioModule/getResourceList"
                  :formatListWith="['id', 'nombre']"
                  no-validate
                  default-text="Seleccionar edificio"
                  required
                />
              </b-col>
            </b-row>

            <b-row class="mt-3">
              <b-col cols="12" md="6">
                <strong class="font-weight-bolder small text-sm text-black">Condominio <span class="text-danger">*</span></strong>
                <x-select
                  class="mt-2"
                  v-model="formValues.condominio_id"
                  dispatchPath="condominioModule/getResource"
                  getterListPath="condominioModule/getResourceList"
                  :formatListWith="['id', 'nombre']"
                  no-validate
                  default-text="Seleccionar condominio al que pertenece el departamento"
                  required
                />
              </b-col>
              <b-col cols="12" md="6">
                <strong class="font-weight-bolder small text-sm text-black">Subcondominio <span class="text-danger">*</span></strong>
                <x-select
                  class="mt-2"
                  v-model="formValues.subcondominio_id"
                  dispatchPath="subcondominioModule/getResource"
                  getterListPath="subcondominioModule/getResourceList"
                  :formatListWith="['id', 'nombre']"
                  no-validate
                  default-text="Seleccionar subcondominio al que pertenece el departamento"
                  required
                />
              </b-col>
            </b-row>

            <b-row class="mt-3">
              <b-col cols="12" md="6">
                <FormulateInput
                  name="tipo_departamento_id"
                  label="Tipo de departamento"
                  required
                  type="select"
                  :options="[
                    { value: 1, label: 'DOBLES' },
                    { value: 2, label: 'SINGLES' },
                    { value: 3, label: 'SUITES' },
                    { value: 4, label: 'SUITES (AMP)' }
                  ]"
                />
              </b-col>

              <b-col cols="12" md="6">
                <strong class="font-weight-bolder small text-sm text-black">Forma de pago</strong>
                <x-select
                  class="mt-2"
                  v-model="formValues.forma_pago_id"
                  dispatchPath="formaDePagoModule/getResource"
                  getterListPath="formaDePagoModule/getResourceList"
                  :formatListWith="['id', 'nombre']"
                  no-validate
                  default-text="Seleccionar forma de pago"
                />
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" md="6">
                <FormulateInput
                  name="inicio_contrato"
                  label="Inicio de contrato (dd/mm/yyyy)"
                  type="text"
                />
              </b-col>

              <b-col cols="12" md="6">
                <FormulateInput
                  name="termino_contrato"
                  label="Término de contrato (dd/mm/yyyy)"
                  type="text"
                />
              </b-col>
            </b-row>

            <b-row>
              <!-- Renta inicial -->
              <b-col cols="12" md="6">
                <FormulateInput
                  name="renta_inicial"
                  label="Renta inicial"
                  type="text"
                />
              </b-col>

              <!-- Renta inicial -->
              <b-col cols="12" md="6">
                <FormulateInput
                  name="renta_actual"
                  label="Renta actual"
                  type="text"
                />
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" md="6">
                <FormulateInput
                  name="descuento"
                  label="Descuento"
                  type="text"
                />
              </b-col>
              <b-col cols="12" md="6">
                <FormulateInput
                  name="amueblado"
                  label="¿Se encuentra amueblado?"
                  required
                  type="select"
                  :options="[
                    { value: 0, label: 'No' },
                    { value: 1, label: 'Sí' }
                  ]"
                  validation="required"
                  validation-name="Amueblado"
                />
              </b-col>
            </b-row>
          </FormulateForm>
        </b-card>
      </b-overlay>

      <template #modal-footer>
        <!-- Enviar para crear o actualizar -->
        <div class="w-100">
          <x-form-footer-buttons
            :disabled="$refs.departamentoForm ? $refs.departamentoForm.hasErrors : false"
            :loading="isLoading"
            :isEditing="isEditingResource"
            :isCreating="!isEditingResource"
            @on-cancel="resetForm"
            @on-create="submit"
            @on-update="submit"
          ></x-form-footer-buttons>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

const formValues = () => ({
  num_puerta: '',
  num_catastral: '',
  cuenta_predial: '',
  inicio_contrato: '',
  termino_contrato: '',
  renta_inicial: '',
  renta_actual: '',
  descuento: '',
  amueblado: '',
  empresa_id: '',
  condominio_id: '',
  subcondominio_id: '',
  edificio_id: '',
  inversionista_id: '',
  tipo_departamento_id: '',
  forma_pago_id: ''
})

const response = () => ({
  error: false,
  message: '',
  errors: {}
})

const STORE_MODULE = 'departamentoModule'

export default {
  name: 'DepartamentoForm',

  props: {
    value: {
      type: Boolean,
      required: true
    }
  },

  data () {
    return {
      modalEditForm: this.value,

      isLoading: false,

      formValues: formValues(),

      response: response()
    }
  },

  computed: {
    //
    // Forma de pago module
    ...mapState(STORE_MODULE, ['editingResource']),
    ...mapGetters(STORE_MODULE, ['isEditingResource']),

    showFormTitle () {
      return this.isEditingResource
        ? `Editando departamento: '${this.editingResource.num_puerta}'`
        : 'Crear nuevo departamento'
    }
  },

  watch: {
    isEditingResource: {
      handler (value) {
        if (value) {
          const {
            id,
            num_puerta,
            num_catastral,
            cuenta_predial,
            inicio_contrato,
            termino_contrato,
            renta_inicial,
            renta_actual,
            descuento,
            amueblado,
            empresa,
            condominio,
            subcondominio,
            edificio,
            inversionista,
            tipo_departamento,
            forma_pago
          } = this.editingResource
          this.formValues.id = id
          this.formValues.num_puerta = num_puerta
          this.formValues.num_catastral = num_catastral
          this.formValues.cuenta_predial = cuenta_predial
          this.formValues.inicio_contrato = inicio_contrato
          this.formValues.termino_contrato = termino_contrato
          this.formValues.renta_inicial = renta_inicial
          this.formValues.renta_actual = renta_actual
          this.formValues.descuento = descuento
          this.formValues.amueblado = amueblado
          this.formValues.empresa_id = empresa?.id
          this.formValues.condominio_id = condominio?.id
          this.formValues.subcondominio_id = subcondominio?.id
          this.formValues.edificio_id = edificio?.id
          this.formValues.inversionista_id = inversionista?.id
          this.formValues.tipo_departamento_id = tipo_departamento?.id
          this.formValues.forma_pago_id = forma_pago?.id
          return
        }

        this.resetForm()
      }
    },

    value: {
      immediate: true,
      handler (value) {
        this.modalEditForm = value
      }
    },

    modalEditForm: {
      immediate: true,
      handler (value) {
        this.$emit('input', value)
      }
    }
  },

  methods: {
    /**
     * Envía el formulario.
     *
     * Envía el formulario para su creación o su actualización.
     * Dependiendo si la variable "isEditingUser" es verdadera actualizará
     * si no es así entonces mandará crear.
     */
    async submit () {
      console.log('submit')
      this.response = response()
      this.prepareInput()

      this.isLoading = true
      const { error, message, data } = this.isEditingResource
        ? await this.updateResource(this.formValues)
        : await this.createResource(this.formValues)
      this.isLoading = false

      this.$notify({ error, message }, 'Envío de formulario')

      this.response.error = error
      this.response.message = message
      this.response.errors = error ? data.errors : {}

      if (!error) this.resetForm()
    },

    async createResource (form) {
      return await this.$store.dispatch(`${STORE_MODULE}/createResource`, form)
    },

    async updateResource (form) {
      return await this.$store.dispatch(`${STORE_MODULE}/updateResource`, {
        id: form.id,
        payload: form
      })
    },

    /**
     * Prepara los datos antes de enviarlos al backend.
     *
     * Prepara los datos necesarios como la fecha o los roles y permisos que
     * se deben procesar en el backend.
     */
    prepareInput () {
    },

    resetForm () {
      document.activeElement.blur()
      this.$store.commit(`${STORE_MODULE}/setResourceToEdit`, null)
      this.$formulate.reset('departamentoForm')
      this.formValues = formValues()
      this.response = response()

      this.modalEditForm = false
    },

    onShowModal () {
      this.modalEditForm = true
      this.$emit('input', this.modalEditForm)
    }
  }
}
</script>

<style>
.required_form {
  color: red;
}
.isLoading {
  max-height: 400px !important;
  overflow: hidden;
}
</style>
