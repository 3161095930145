<script>
import { mapGetters } from 'vuex'
const STORE_MODULE = 'departamentoModule'

export default {
  name: 'DepartamentoListDetail',

  props: {
    row: {
      required: true
    }
  },

  data () {
    return {
      historialActualizacionesFields: [
        { key: 'fecha_actualizacion', label: 'Fecha de actualización' },
        { key: 'renta_anterior', label: 'Importe anterior' },
        { key: 'factor_actualizacion', label: 'Factor de actualización' },
        { key: 'renta_actual', label: 'Importe actualizado' },
        { key: 'inpc', label: 'INPC (Anterior/Actual)' },
        { key: 'periodos', label: 'Periodos (Anterior/Actual)' }
      ],

      historialRenovacionesFields: [
        { key: 'fecha_actualizacion', label: 'Fecha de actualización' },
        { key: 'fecha_termino_anterior', label: 'Fecha de término anterior' },
        { key: 'fecha_termino_actual', label: 'Fecha de término actual' }
      ],

      historialPagosFields: [
        { key: 'fecha_pago', label: 'Fecha de pago' },
        { key: 'importe_pago', label: 'Importe' },
        { key: 'archivos', label: 'Archivos' }
      ]
    }
  },

  computed: {
    ...mapGetters(STORE_MODULE, [
      'getResourceList',
      'getAvailableList',
      'getTotalRows',
      'isThereData',
      'getResource'
    ])
  },

  methods: {
    async onDownloadFile (archivo) {
      const result = await this.$store.dispatch('archivoModule/download', archivo.id)

      await this.$onDownload(result, archivo.original_name)

      this.$notify({ message: `Descarga de archivo ${archivo.original_name}.` })
    }
  }
}
</script>

<template>
  <b-card
    class="mb-1"
    border-variant="primary"
    header-bg-variant="primary"
    header-text-variant="white"
    header="Detalle"
    no-body
  >
    <b-card-body>
      <b-row class="mb-2">
        <b-col cols="12" md="6">
          <b-card
            header="Historial de pagos"
            no-body
            border-variant="success"
            header-bg-variant="success"
            header-text-variant="light"
            class="mb-4"
            style="height: 524px; overflow: auto;"
          >
            <b-table
              id="historialActualizacionesImporteTable"
              v-if="getAvailableList"
              :fields="historialPagosFields"
              :items="row.item.historial_pagos"
              striped
              bordered
              responsive
              hover
              stacked="lg"
              show-empty
              small
              empty-text="No existe información de pagos registrados para este departamento"
            >
              <template #cell(fecha_pago)="row">
                <span class="text-sm text-center w-100">
                  {{ row.item.fecha_pago }}
                </span>
              </template>
              <template #cell(importe_pago)="row">
                <span class="text-sm text-center w-100">
                  {{ $formatNumber(row.item.importe_pago) }}
                </span>
              </template>
              <template #cell(archivos)="row">
                <span class="text-sm text-left w-100">
                  <ul>
                    <li v-for="archivo in row.item.archivos" v-bind:key="archivo.id">
                      <b-link @click="onDownloadFile(archivo)">
                        {{ archivo.original_name }}
                      </b-link>
                    </li>
                  </ul>
                </span>
              </template>
            </b-table>
          </b-card>
        </b-col>
        <b-col cols="12" md="6">
          <b-card
            header="Historial de actualizaciones de importe"
            no-body
            border-variant="primary"
            class="mb-4"
            style="height: 250px; overflow: auto;"
          >
            <b-table
              id="historialActualizacionesImporteTable"
              v-if="getAvailableList"
              :fields="historialActualizacionesFields"
              :items="row.item.historial_actualizaciones"
              striped
              bordered
              responsive
              hover
              stacked="lg"
              show-empty
              small
              empty-text="No existe información de actualizaciones de importe por INPC para este departamento"
            >
              <template #cell(fecha_actualizacion)="row">
                          <span class="text-sm text-center w-100">
                            {{ row.item.fecha_actualizacion }}
                          </span>
              </template>
              <template #cell(factor_actualizacion)="row">
                          <span class="text-sm text-center w-100">
                            {{ row.item.factor_actualizacion }}
                          </span>
              </template>
              <template #cell(renta_anterior)="row">
                          <span class="text-sm text-center w-100">
                            {{ $formatNumber(row.item.renta_anterior) }}
                          </span>
              </template>
              <template #cell(renta_actual)="row">
                          <span class="text-sm text-center w-100">
                            {{ $formatNumber(row.item.renta_actual) }}
                          </span>
              </template>
              <template #cell(inpc)="row">
                          <span class="text-sm text-center w-100">
                            {{ row.item.inpc_anterior.indice }} / {{ row.item.inpc_actual.indice }}
                          </span>
              </template>
              <template #cell(periodos)="row">
                          <span class="text-sm text-center w-100">
                            {{ row.item.periodo_anterior.identificacion }} / {{ row.item.periodo_actual.identificacion }}
                          </span>
              </template>
            </b-table>
          </b-card>
          <b-card
            no-body
            header="Historial de renovaciones de contrato"
            header-bg-variant="warning"
            header-text-variant="light"
            border-variant="warning"
            style="height: 250px; overflow: auto;"
          >
            <b-table
              id="historialRenovacionesTable"
              v-if="getAvailableList"
              :fields="historialRenovacionesFields"
              :items="row.item.historial_renovaciones"
              striped
              bordered
              responsive
              hover
              stacked="lg"
              show-empty
              small
              empty-text="No existe información de renovaciones de contrato para este departamento"
            >
              <template #cell(fecha_actualizacion)="row">
                <span class="text-sm text-center w-100">
                  {{ row.item.fecha_actualizacion }}
                </span>
              </template>
              <template #cell(fecha_termino_anterior)="row">
                <span class="text-sm text-center w-100">
                  {{ row.item.fecha_termino_anterior }}
                </span>
              </template>
              <template #cell(fecha_termino_actual)="row">
                <span class="text-sm text-center w-100">
                  {{ row.item.fecha_termino_actual }}
                </span>
              </template>
            </b-table>
          </b-card>
        </b-col>
      </b-row>
      <b-card no-body>
        <b-button
          variant="white"
          size="sm"
          class="btn-outline-black"
          @click="row.toggleDetails"
        >
          Cerrar detalle
        </b-button>
      </b-card>
    </b-card-body>
  </b-card>
</template>

<style scoped>

</style>
